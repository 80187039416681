@import "./css/vars";
@import "./css/reset";
@import "./css/fonts";
@import "./css/global";

@import "./css/tmp-scss/header";
@import "./css/tmp-scss/menu";
@import "./css/tmp-scss/social";
@import "./css/tmp-scss/consultation";
@import "./css/tmp-scss/form";
@import "./css/tmp-scss/input";
@import "./css/tmp-scss/popup";
@import "./css/tmp-scss/breadcrumbs";
@import "./css/tmp-scss/promo-page";
@import "./css/tmp-scss/404";
@import "./css/tmp-scss/footer";

@import "./css/tmp-scss/cabinet/registration";
@import "./css/tmp-scss/cabinet/cabinet";
@import "./css/tmp-scss/cabinet/authorization";
@import "./css/tmp-scss/cabinet/popup-cab";
@import "./css/tmp-scss/cabinet/login";
@import "./css/tmp-scss/cabinet/ant";
@import "./css/tmp-scss/cabinet/load";
@import "./css/tmp-scss/cabinet/documents";
@import "./css/tmp-scss/cabinet/preparation";
@import "./css/tmp-scss/cabinet/aboutuser";
@import "./css/tmp-scss/cabinet/courses";
@import "./css/tmp-scss/cabinet/subjects";
@import "./css/tmp-scss/cabinet/lessons";
@import "./css/tmp-scss/cabinet/editor";
@import "./css/tmp-scss/cabinet/testConstructor";
@import "./css/tmp-scss/cabinet/listLessons";
@import "./css/tmp-scss/cabinet/lesson";
@import "./css/tmp-scss/cabinet/test";
@import "./css/tmp-scss/cabinet/textBook";
@import "./css/tmp-scss/cabinet/textBook-students";
@import "./css/tmp-scss/cabinet/aside-book";
@import "./css/tmp-scss/cabinet/notification";
@import "./css/tmp-scss/cabinet/certification";

.header {
  z-index: 103;

  &._menu-open {
    z-index: 0;
  }
}
