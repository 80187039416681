.notification {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__block {
    border-radius: 20px;
    padding: 20px;
    background-color: #dde5ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__block2 {
    border-radius: 20px;
    padding: 20px;
    background-color: #d0ecb3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__title {
    margin: 10px 0;
  }

  &__text {
    text-align: center;
  }

  &__icon {
    background-image: url("../../../images/cat/img_cat_sitting.svg");
    height: 243px;
    width: 220px;
  }
  &__icon2 {
    background-image: url("../../../images/cat/Group.svg");
    background-size: 100%;
    width: 300px;
    height: 195px;
  }
  &__icon3 {
    background-image: url("../../../images/cat/class3z.svg");
    width: 264px;
    height: 186px;
  }

  &__btn {
    margin: 10px 0;
  }
}
