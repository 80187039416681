.certification {
  &__title {
    margin-bottom: 30px;
  }

  &__info-block {
    padding: 40px 30px;
    width: 100%;
    border: 1px solid #67676a;
    border-radius: 20px;
    position: relative;

    &__background {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background-color: #dde5ff;
      z-index: -1;
      box-shadow: 4px 4px 10px 0px #6b80894d;
    }

    &__attention {
      margin-top: 12px;
    }
  }

  &__table-wrapper {
    width: 100%;
    overflow: auto;
  }

  &__table {
    width: 100%;
    min-width: 500px;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  &__table-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
  }

  &__table-cell {
    min-width: 22%;
    margin-right: 10px;
    word-wrap: normal;
  }
  &__table-cell-subject {
    min-width: 30%;
  }
  &__table-cell-red {
    color: #ff8080;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: #67676a;
    margin-bottom: 30px;
    margin-top: 6px;
  }

  &__start-button {
    background-color: #ffd152;
    border-radius: 20px;
    padding: 5px 15px 7px 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
  }
  &__complete-button {
    background-color: #a1da67;
    border-radius: 20px;
    padding: 5px 15px 7px 15px;
    display: flex;
    justify-content: space-around;
  }

  &__test {
    background-color: #00000000;
    padding: 0 !important;

    &__title {
      border-bottom: 1px solid #67676a;
    }

    .test__inputs {
      background-color: #fff1cb;
      padding: 10px 15px;
      border-radius: 20px;
      max-width: 60%;

      .test__input {
        background-color: #fff1cb;
        border-bottom: 1px solid #67676a;
      }
    }

    ._error {
      .test__inputs {
        background-color: rgba(0, 0, 0, 0);

        .test__input {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  &__subject {
    margin-bottom: 30px;
  }

  &__attention-modal {
    .ant-modal-content {
      background-color: #8fa9ff;
      border-radius: 20px;
    }
    .ant-modal-title {
      background-color: #8fa9ff;
      color: white;
      text-align: center;
      font-size: 40px;
      font-weight: bold;
    }
    .ant-modal-body {
      color: white;
      text-align: center;
      font-size: 18px;
    }
    .ant-modal-close-x {
      color: white;
    }
  }
  &__attempt-modal {
    .ant-modal-content {
      padding: 30px;
    }
    .ant-modal-title {
      margin-bottom: 20px;
      font-size: 24px;
    }
  }
  &__modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 25px 0 15px 0;

    &__white-btn {
      width: 40%;
      border-color: white;
      color: white;
    }
    &__danger-btn {
      background-color: #ff8080;
      width: 40%;
      border: none;
      border-color: #ff8080;
      font-weight: bold;
      color: white;
    }
    &__yellow-btn {
      background-color: #ffd152;
      min-width: 40%;
      border: none;
      color: var(--grey-color);
    }
  }

  &__text-bold {
    font-weight: 700;
  }
}

@media screen and (max-width: 800px) {
  .certification {
    &__table-cell {
      font-size: 14px;
    }

    &__available-text {
      display: none;
    }

    &__attention-modal {
      .ant-modal-content {
      }
      .ant-modal-title {
        font-size: 28px;
      }
      .ant-modal-body {
        font-size: 14px;
      }
    }
    &__attempt-modal {
      .ant-modal-title {
        font-size: 20px;
        line-height: 100%;
      }
      .ant-modal-body {
        font-size: 14px;
        line-height: 120%;
      }
    }

    &__test {
      .test__inputs {
        max-width: 100%;
      }
    }
  }
}
