.payform-tinkoff {
  flex: 1;
  width: 100%;
}

.payform-tinkoff-row {
  margin: 2px;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #DFE3F3;
  padding: 15px;
  outline: none;
  background-color: #DFE3F3;
  font-size: 15px;
}

.payform-tinkoff-row:focus {
  background-color: #FFFFFF;
  border: 1px solid #616871;
  border-radius: 4px;
}

.payform-tinkoff-btn {
  width: 100%;
}

.payform-tinkoff-btn:hover {
  background-color: #FAB619;
  border: 1px solid #FAB619;
}

.payform-tinkoff-btn:disabled {
  background-color: #D3D9DC;
  border: 1px solid #D3D9DC;
  cursor: default;
}