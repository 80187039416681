.registration {
  @include adpt($tab-r) {
    overflow: hidden;
  }

  &__aside {
    @include adpt($tab-r) {
      display: flex;
      align-items: center;
    }

    @include adpt($bmob-r) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__aside-item {
    padding: 20px;
    border-radius: 20px;
    border: 1px solid $grey;

    @include adpt($bmob-r) {
      width: 100%;
      text-align: center;
    }
  }

  &__aside-name {
    margin-bottom: 10px;

    @include adpt($bmob-r) {
      margin-bottom: 5px;
    }
  }

  &__aside-other {
    margin-top: 30px;
    position: relative;

    @include adpt($tab-r) {
      margin-top: 0;
      margin-left: 30px;
    }

    @include adpt($bmob-r) {
      margin: 30px auto 0;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: $grey;
    }
  }

  &__aside-list-item {
    font-variant-numeric: lining-nums proportional-nums;

    & b {
      font-weight: 700;
    }

    & span {
      @include adpt($bmob-r) {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__aside-blue {
    border-radius: 20px;
    padding: 20px;
    background-color: #dde5ff;
    width: 260px;
    &__title {
      margin-bottom: 10px;
    }
  }

  &__cat-sitting {
    background-image: url("../../../images/cat/img_cat_sitting.svg");
    width: 100%;
    height: 243px;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__nav {
    margin-bottom: 50px;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey;
  }

  &__nav-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include adpt($stab-r) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__nav-item {
    @include adpt($stab-r) {
      width: 100%;
    }

    &._active {
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-right: 20px;

      @include adpt($stab-r) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  &__select-class {
    margin-bottom: 30px;
  }

  &__select-class-title {
    margin-bottom: 20px;
  }

  &__decor {
    position: absolute;
    object-fit: contain;
    z-index: 0;
    pointer-events: none;

    @include adpt($bmob-r) {
      display: none;
    }

    &._1 {
      max-width: 35%;
      height: auto;
      top: -90px;
      right: 0;

      @include adpt($tab-r) {
        transform: translateX(90%);
      }
    }

    &._2 {
      max-width: 30%;
      height: auto;
      top: 20%;
      left: 0;

      @include adpt($tab-r) {
        display: none;
      }
    }

    &._3 {
      max-width: 40%;
      height: auto;
      bottom: 19%;
      right: 0;

      @include adpt($tab-r) {
        transform: translateX(80%);
      }
    }
  }

  &__mb {
    &:not(:last-child) {
      margin-bottom: 50px;
      padding-bottom: 49px;
      border-bottom: 1px solid $grey;
    }
  }

  &__subject-title {
    margin-bottom: 10px;

    @include adpt($bmob-r) {
      font-size: 18px;
    }
  }

  &__subject-name {
    position: relative;
    z-index: 1;

    @include adpt($bmob-r) {
      font-size: 18px;
    }
  }

  &__subject-text {
    margin-bottom: 30px;
  }

  &__subject-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    @include adpt($stab-r) {
      grid-template-columns: 1fr 1fr;
    }

    @include adpt($bmob-r) {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }

  &__subject-item {
    position: relative;
    border-radius: 20px;
    border: 1px solid $grey;
    padding: 20px;
    min-height: 154px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    background-color: $white;
    padding-top: 80px;
    transition: all 0.3s;

    &:hover {
      background-color: $blue;
      color: $white;
      border-color: $blue;
      box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.3);

      & svg path {
        stroke: $white;

        &[fillColor] {
          fill: $white;
        }
      }
    }
  }

  &__subject-img-wrap {
    width: 60px;
    height: 60px;
    object-fit: contain;
    position: absolute;
    z-index: 0;
    left: 20px;
    top: 18px;
  }

  &__subject-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    z-index: 0;

    & path {
      stroke: var(--grey-color);
      transition: all 0.3s;
    }
  }

  &__form-title {
    margin-bottom: 30px;
  }

  &__format-item {
    padding: 40px 30px;
    border-radius: 20px;
    background-color: $blueLight;
    box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.3);
    transition: box-shadow 0.3s, border-color 0.3s;

    @include adpt($bmob-r) {
      padding: 30px 20px;
    }

    &._second {
      box-shadow: none;
      background-color: transparent;
      border: 1px solid $grey;

      & .registration__format-list-item {
        &::after {
          background-image: url("../../../images/icon/check2.svg");
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__format-title {
    margin-bottom: 30px;
  }

  &__format-text {
    margin-bottom: 30px;
  }

  &__list-item {
    position: relative;
    padding-left: 34px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url("../../../images/icon/check.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:not(:last-child) {
      margin-bottom: 11px;
    }
  }

  &__format-list-item {
    &:first-child {
      margin-bottom: 30px;
      font-weight: 700;
      padding: 0;

      &::after {
        display: none;
      }
    }
  }

  &__format-input-check {
    position: absolute;
    z-index: -1;
    width: 0;
    pointer-events: none;
    opacity: 0;

    &:checked + label {
      &::after {
        opacity: 0;
      }

      &:before {
        opacity: 1;
      }
    }
  }

  &__format-check-label {
    padding-left: 34px;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background-image: url("../../../images/icon/check-square-0.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 0;
      transition: opacity 0.3s;

      @include adpt($bmob-r) {
        top: -4px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      z-index: 1;
      background-image: url("../../../images/icon/check-square-1.svg");
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0;
      transition: opacity 0.3s;

      @include adpt($bmob-r) {
        top: -4px;
      }
    }
  }

  &__enrollment-title {
    margin-bottom: 30px;
  }

  &__enrollment-subtitle {
    margin-bottom: 20px;
  }

  &__enrollment-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @include adpt($stab-r) {
      grid-template-columns: 1fr;
    }
  }

  &__enrollment-item {
    padding: 40px 30px;
    border-radius: 20px;
    border: 1px solid $grey;

    @include adpt($bmob-r) {
      padding: 30px 20px;
    }
  }

  &__radio-input {
    width: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    &:checked + label {
      &::before {
        opacity: 1;
      }
    }
  }

  &__radio-label {
    display: block;
    cursor: pointer;
    position: relative;
    padding-left: 34px;

    &::after,
    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
    }

    &::after {
      top: 3px;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid $grey;
    }

    &::before {
      top: 7px;
      left: 4px;
      width: 12px;
      height: 12px;
      background-color: $grey;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &__enrollment-label {
    margin-bottom: 30px;
  }

  &__attachment {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  &__attachment-subtitle {
    font-weight: 700;
  }

  &__study-title {
    margin-bottom: 30px;
  }

  &__study-subtitle {
    margin-bottom: 20px;
  }

  &__btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    @include adpt($bmob-r) {
      flex-direction: column;
    }
  }

  &__btn-next {
    flex: 1;
  }

  &__study-inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  &__study-item {
    padding: 40px 30px;
    border-radius: 20px;
    border: 1px solid $grey;

    @include adpt($bmob-r) {
      padding: 30px 20px;
    }
  }

  &__study-text {
    margin-top: 30px;

    & b {
      font-weight: 700;
    }

    & p:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__btn {
    padding: 10px 30px;

    @include adpt($bmob-r) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 20px;

      @include adpt($bmob-r) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  &__student-title {
    margin-bottom: 30px;
  }

  &__student-subtitle {
    margin-bottom: 20px;

    &._nmb {
      margin-bottom: 10px;
    }
  }

  &__inputs-inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__inputs-row {
    display: flex;
    justify-content: space-between;

    @include adpt($bmob-r) {
      flex-direction: column;
    }
  }

  &__input-wrap {
    flex: 1;

    &:not(:last-child) {
      margin-right: 20px;

      @include adpt($bmob-r) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  &__student-select {
    margin-bottom: 30px;
  }

  &__input {
    border: 1px solid $grey;
  }

  &__student-text {
    margin-bottom: 20px;
  }

  &__parent-radio {
    margin-bottom: 30px;
  }

  &__parent-label {
    margin-bottom: 20px;
  }

  &__contact {
    margin-top: 50px;
  }

  &__contact-title {
    margin-bottom: 10px;
  }

  &__contact-text {
    margin-bottom: 30px;
  }

  &__document-title {
    margin-bottom: 30px;
  }

  &__document-text {
    margin-bottom: 30px;

    & p:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__document-subtitle {
    margin-bottom: 10px;
  }

  &__document-subtitle-two {
    margin-bottom: 10px;
    margin-top: 30px;
  }

  &__document-item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__document-input {
    position: absolute;
    z-index: -1;
    width: 0;
    opacity: 0;
    pointer-events: none;
  }

  &__document-item-text {
    margin-bottom: 20px;
  }

  &__document-input-wrap {
    padding: 40px 30px;
    border-radius: 20px;
    border: 1px solid $grey;

    @include adpt($bmob-r) {
      padding: 20px;
    }
  }

  &__document-label {
    padding: 9px 54px 9px 20px;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      right: 20px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-image: url("../../../images/icon/download.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__document-page-title {
    margin-bottom: 20px;
  }

  &__document-page-text {
    margin-bottom: 50px;
  }

  &__document-page-img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  &__document-page-img-wrap {
  }

  & page {
    box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.3);

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__document-page-img-wrap-inner {
    padding-bottom: 49px;
    margin-bottom: 50px;
    border-bottom: 1px solid $grey;
  }

  &__conclusion-title {
    margin-bottom: 10px;
  }

  &__conclusion-text {
    margin-bottom: 30px;
  }

  &__conclusion-checkbox {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;

    label {
      padding-left: 6px;
      font-size: 18px;
      font-weight: bold;
      color: #67676a;
    }
  }

  &__conclusion-btn-wrap {
    display: flex;

    @include adpt($bmob-r) {
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }

  &__conclusion-input-wrap {
    flex: 1;
    margin-right: 20px;

    @include adpt($bmob-r) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__conclusion-btn {
    flex: 1;
    padding: 8px 20px 9px;
  }

  &__conclusion-btn-repeat {
    text-decoration: underline;
  }

  &__success {
    margin-bottom: 50px;
  }

  &__success-title {
    margin-bottom: 10px;
  }

  &__confirm-page-title {
    margin-bottom: 20px;
  }

  &__confirm-page-text {
    margin-bottom: 20px;
  }

  &__confirm-page-tariff {
    border-radius: 20px;
    padding: 40px 30px;
    background-color: #d0ecb3;
  }

  &__confirm-page-item {
    padding: 8px 0;
  }

  &__fill-checkbox {
    background-image: url("../../../images/icon/check-square-1.svg");
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    top: 4px;
  }
  &__empty-checkbox {
    background-image: url("../../../images/icon/check-square-0.svg");
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    top: 4px;
  }
  &__document-page-checkbox {
    cursor: pointer;
  }
  &__confirm-page-divider {
    width: 100%;
    height: 1px;
    background-color: #67676a;
    margin: 10px 0;
  }
  &__confirm-page-margin-divider {
    width: 100%;
    height: 1px;
    background-color: #67676a;
    margin: 30px 0;
  }
  &__confirm-page-divider-vertical {
    width: 1px;
    height: 100%;
    min-height: 250px;
    background-color: #67676a;
    margin: 0 20px;
  }
  &__confirm-page-info {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 40px 30px;
  }
  &__confirm-page-info-small {
    display: flex;
    align-items: center;
    height: 50%;
    width: 100%;
  }
  &__confirm-page-info-small-block {
    width: 50%;
  }
  &__confirm-page-back-btn {
    width: 100%;
    margin-top: 50px;
  }
  &__confirm-page-pay-btn {
    background-color: #8fa9ff;
    border-color: #8fa9ff;
    color: white;
  }
  &__confirm-page-pay-btn:hover {
    background-color: #8fa9ff !important;
    border-color: #8fa9ff !important;
  }
}
